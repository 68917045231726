/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Close, Favorite, FavoriteBorder } from "@material-ui/icons";
import {
  Box,
  Grid,
  Avatar,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  FormControl,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  withStyles,
  LinearProgress,
  Container,
  IconButton,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { apiCall } from "../../../utils";
import { useScene } from "../../../hooks/useScene";
import { useStyles } from "./styled";
import { userActions, voteActions } from "../../../actions";
import { APP_SCENE, FORM_CONSTANTS, PRIMARY_COLOR, PROFILE_API, ROLE, ROUTER, SECONDARY_COLOR } from "../../../constants";

import qcvVote from "../../../assets/image/tnv/qcv-vote.jpg";
// import backdrop from "../../../assets/image/home/backdrop.jpg";
import contract from "../../../assets/image/contract.png";
import backdrop from '../../../assets/image/banner-bg.png';
import logoDoan from "../../../assets/image/home/logoDoan.png";
import logoBoKHCN from "../../../assets/image/home/logoBoKHCN.png";
import Loading from "../../CustomComponents/Loading";
import { useScreenSize } from "../../../hooks/useScreenSize";

const fielQCVdOptions = [
  { key: 'all', title: 'Toàn bộ', value: 'all' },
  ...FORM_CONSTANTS.FIELD_OPTIONS[APP_SCENE],
];

const fielQCVdOptionsObj = {};

fielQCVdOptions.forEach(f => {
  fielQCVdOptionsObj[f.key] = f.title;
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: SECONDARY_COLOR,
  },
}))(LinearProgress);

const BinhChonV2 = () => {
  const scene = useScene();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileMode = useScreenSize();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const refreshToken = localStorage.getItem('refresh_token');

  const [filter, setFilter] = useState({ option: 'all' });
  const [loading, setLoading] = useState(false);
  const [allProfile, setAllProfile] = useState([]);
  const [maxVote, setMaxVote] = useState(20);
  
  const voteInDB = useSelector(state => state.voteReducer.votedList);
	const loadingVote = useSelector(state => state.voteReducer.loading);

  const role = useSelector(state => state.userReducer.user?.role);
  const profile = useSelector(state => state.userReducer.user);
  const openSeasonId = useSelector((state) => state.seasonReducer.openSeasonId);

  const vote = useMemo(() => voteInDB.filter((item) => {
    const form = allProfile.find((f) => {
      const fVoteStatus = f.voteStatus || '';
      const itemType = item.type || '';
      return f.id === item.applicationFormId && fVoteStatus === itemType;
    });
    return !!form;
  }), [voteInDB, allProfile]);

  const fieldOptions = useMemo(() => [
    { key: 'all', title: 'Toàn bộ', value: 'all' },
    ...FORM_CONSTANTS.FIELD_OPTIONS[APP_SCENE],
  ], [scene]);

  const voteStatus = useMemo(() => {
    // TODO: năm sau mấy con số này lưu vào DB
    if (scene.isQuaCauVang) {
      setMaxVote(10);
      return 'top10';
    } else if (scene.isGiaiThuongNuSinh) {
      setMaxVote(20);
      return 'top20';
    } else if (scene.isKhueVanCac) {
      setMaxVote(10);
      return 'top10';
    }
  }, [scene]);

  const profiles = useMemo(() => {
    if (filter.option === "all") {
      // allProfile.sort((a, b) => a.scoreG - b.scoreG);
      return allProfile.sort((a, b) => a.id - b.id);
    } else { 
      const list = allProfile.filter((p) => p.field === filter.option);
      // list.sort((a, b) => a.scoreG - b.scoreG);
      return list.sort((a, b) => a.scoreG - b.scoreG);
    }
  }, [filter.option, allProfile]);

  const checkVoted = (p) => {
    const isVoted = !!vote?.find(v => v.applicationFormId === p.id);
    return isVoted;
  }

  const noti = (notification) => {
    enqueueSnackbar(notification?.message, {
      variant: notification?.type || 'default',
      action: (key) => (
        <IconButton size='small' onClick={() => closeSnackbar(key)}>
          <Close size='small' style={{ color: 'white' }} />
        </IconButton>
      ),
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    });
  }

  const handleFilter = async () => {
    const api = PROFILE_API.getProfileFilterForm();

    setLoading(true);

    const { response, error } = await apiCall({
      ...api,
      payload: {
        seasonId: openSeasonId,
        category: APP_SCENE,
        voteStatus,
        // status: ["approved"]
      }
    });
    setLoading(false);
    if (!error && response.status === 200 && response.data.success) {
      setAllProfile(response.data.data || []);
    }
  };

  const handleGetVote = () => {
    const voteInfo = {
      credential: profile?.username,
      type: 'council',
    }

    dispatch(voteActions.getVoteInfo({ credential: voteInfo?.credential }));
    dispatch(voteActions.setVoteInfo(voteInfo));
    dispatch(voteActions.getVoteCount());
  }

  const handleVote = (profileVote) => {
    const oldVotes = vote?.map(v => v.applicationFormId);
    const voteInfo = {
      credential: profile?.username,
      type: voteStatus,
      listFormId: [...oldVotes, profileVote.id],
    }

    if (voteInfo.listFormId.length > maxVote) {
     
      noti({ message: `Chỉ được bình chọn tối đa ${maxVote} ứng viên!`, type: 'error' });
    } else {
      dispatch(voteActions.vote(voteInfo, () => {
        handleGetVote();
        noti({ message: 'Bình chọn thành công!', type: 'success' });
      }));
    }
  }

  const handleUnVote = (profileVote) => {
    const oldVotes = vote?.map(v => v.applicationFormId);
    const newVotes = oldVotes.filter(v => v !== profileVote.id);
    const voteInfo = {
      credential: profile?.username,
      type: voteStatus,
      listFormId: [...newVotes],
    }

    dispatch(voteActions.vote(voteInfo, () => {
      handleGetVote();
      noti({ message: 'Bỏ bình chọn thành công', type: 'success' });
    }));
  }

  useEffect(() => {
    if (openSeasonId && voteStatus) {
      handleGetVote();
      handleFilter();
    }
  }, [openSeasonId, voteStatus, profile]);

  useEffect(() => {
    if (!refreshToken || (role && role !== ROLE.SUPER_ADMIN && role !== ROLE.COUNCIL && role !== ROLE.COUNCIL_QCV && role !== ROLE.COUNCIL_GTNS && role !== ROLE.COUNCIL_KVC)) {
      navigate(ROUTER.PAGE_401);
    }
  }, [role]);

  return (
    <>
      <Container maxWidth='lg'>
        <Box className={classes.imageHead}>
          <Box className={classes.logoHeader} >
            <Box mr={"40px"} className="group-logo">
              <img width={80} src={logoDoan} style={{ margin: 'auto' }} />
              <Typography variant="h6">TRUNG ƯƠNG ĐOÀN TNCS HỒ CHÍ MINH</Typography>
            </Box>
            {!scene.isKhueVanCac && (
              <Box className="group-logo">
                <img width={80} src={logoBoKHCN} />
                <Typography variant="h6">BỘ KHOA HỌC VÀ CÔNG NGHỆ</Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Grid container spacing={3} justifyContent='space-between' className={classes.filter}>
          <Grid item xs={12} md={6} lg={4}>
            <Button
              color='secondary'
              variant='contained'
              disabled={role !== "super_admin"}
              onClick={() => navigate(ROUTER.QCV.KET_QUA_BINH_CHON)}
            >
              Xem kết quả bình chọn (admin)
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl size='small' variant='outlined' fullWidth>
              <InputLabel id="test-select-label">Lĩnh vực</InputLabel>
              <Select
                fullWidth
                value={filter.option}
                MenuProps={{
                  getContentAnchorEl: null,
                  MenuListProps: { style: { padding: 0 } },
                  PaperProps: { style: { left: 8 } },
                  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                  transformOrigin: { vertical: 'top', horizontal: 'center' },
                }}
                onChange={(e) => setFilter({ ...filter, option: e.target.value })}
                label='Lĩnh vực'
              >
                {fieldOptions?.map((opt) => <MenuItem key={opt.key} value={opt.key}>{opt.title}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ minHeight: '100vh' }}>
          {profiles.map((p, index) => {
            return (
              <Grid
                item
                md={4}
                xs={12}
                key={index}
                classes={{ root: classes.gridCard }}
                style={{ height: '100%' }}
              >
                <Card style={{ borderRadius: 8 }}>
                  <CardActionArea className="card-area">
                    <CardMedia
                      component="img"
                      classes={{ root: classes.header }}
                      src={qcvVote}
                    />
                    <Avatar
                      src={`${process.env.REACT_APP_DOMAIN}${p?.profile?.photoUrl}`}
                      style={{
                        width: 100,
                        height: 100,
                        border: "solid 4px #fff",
                        margin: '-50px auto 0 auto',
                      }}
                    />
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "125px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "1.3rem",
                          marginBottom: "20px",
                          fontWeight: "bold",
                          fontFamily: "WorkSans-ExtraBold"
                        }}
                      >
                        {p.profile.name}
                      </Typography>
                      <Box className={classes.info}>
                        <Typography style={{ width: '100%', textAlign: "center" }}><b>Lĩnh vực: </b>{fielQCVdOptionsObj[p.field]}</Typography>
                        <Typography style={{ width: '100%', textAlign: "center" }}><b>Ngày sinh: </b>{dayjs(p?.profile?.birth).format("DD/MM/YYYY")}</Typography>
                      </Box>
                    </CardContent>
                    <Box className={classes.vote}>
                      <Button
                        style={{
                          backgroundColor: checkVoted(p) ? "#EE6064" : undefined,
                          color: checkVoted(p) ? "#FFFFFF" : undefined,
                          border: checkVoted(p) ? "0px" : undefined,
                          margin: 'auto'
                        }}
                        startIcon={checkVoted(p) ? <Favorite color="#FFFFFF" /> : <FavoriteBorder />}
                        onClick={() => {
                          checkVoted(p) ? handleUnVote(p) : handleVote(p)
                        }}
                      >{checkVoted(p) ? "Đã bình chọn" : "Bình chọn"}</Button>
                    </Box>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })}
        </Grid>

        <Loading open={loading || loadingVote} />
      </Container>

      <Box mt={4} className={classes.bottom}>
        <Container maxWidth='md'>
          <Card style={{
            backgroundImage: `url(${backdrop})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: isMobileMode ? 96 : 128,
          }}>
            <Box display='flex' alignItems='center' justifyContent='space-between' style={{ height: isMobileMode ? 96 : 128 }}>
              <Box paddingLeft={isMobileMode ? 2 : 4}>
                <Box flexGrow={1} marginBottom={1} display='flex' alignItems='center'>
                  <Typography variant={isMobileMode ? 'body1' : 'h4'} style={{ color: PRIMARY_COLOR }}>
                    <b>Đã bình chọn: </b>
                  </Typography>
                  <Typography variant={isMobileMode ? 'h6' : 'h3'} style={{ color: PRIMARY_COLOR, fontFamily: "WorkSans-ExtraBold" }}>
                    <b><>&nbsp;</><span style={{ color: SECONDARY_COLOR }}>{vote?.length}</span>/{maxVote}</b>
                  </Typography>
                </Box>
                <BorderLinearProgress variant="determinate" value={vote?.length / maxVote * 100} style={{ width: '125%' }} />
              </Box>
              <Box height='100%' paddingTop={2} paddingRight={isMobileMode ? 2 : 4}>
                <img alt='contract' src={contract} style={{ height: '100%' }} />
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default BinhChonV2;