/*eslint no-unsafe-optional-chaining: "off"*/
import { CATEGORY, FORM_TYPE } from '../constants';
import { PROFILE_TYPE } from '../constants';
import { thanhTichList } from '../utils';

const initialState = {
  applicationList: [],
  applicationSearchList: [],
  application: null,
  myApplicationFormList: [],
  applicationFilter: [],
  focusForm: null,
  loading: false,
  updating: false,
  thongKeLoading: false,
  thongKeInfo: null,
  applicationv2:null
}

export function formReducer(state = initialState, action) {
  switch (action.type) {
    case FORM_TYPE.FILTER_APPLICATION_FORM_LOADING:
    case FORM_TYPE.FILTER_APPLICATION_FORM_SEARCH_LOADING:
    case FORM_TYPE.GET_APPLICATION_BY_PROFILE_ID_LOADING:
    case PROFILE_TYPE.GET_PROFILE_LIST_LOADING:
    case FORM_TYPE.GET_APPLICATION_FORM_V2_LOADING:
    case FORM_TYPE.APPROVE_APPLICATION_FORM_LOADING:
    case PROFILE_TYPE.GET_PROFILE_FILTER_FORM_LOADING:
    case FORM_TYPE.UPDATE_BCD_APPLICATION_FORM_LOADING:
      return { ...state, loading: true };

    case FORM_TYPE.INSERT_APPLICATION_FORM_LOADING:
    case FORM_TYPE.UPDATE_APPLICATION_FORM_LOADING:
      return { ...state, updating: true };

    case FORM_TYPE.FILTER_APPLICATION_FORM_FAILURE:
    case FORM_TYPE.FILTER_APPLICATION_FORM_SEARCH_FAILURE:
    case FORM_TYPE.GET_APPLICATION_BY_PROFILE_ID_FAILURE:
    case PROFILE_TYPE.GET_PROFILE_LIST_FAILURE:
    case FORM_TYPE.GET_APPLICATION_FORM_V2_FAILURE:
    case FORM_TYPE.APPROVE_APPLICATION_FORM_FAILURE:
    case PROFILE_TYPE.GET_PROFILE_FILTER_FORM_FAILURE:
    case FORM_TYPE.UPDATE_BCD_APPLICATION_FORM_FAILURE:
      return { ...state, loading: false };

    case FORM_TYPE.INSERT_APPLICATION_FORM_FAILURE:
    case FORM_TYPE.UPDATE_APPLICATION_FORM_FAILURE:
      return { ...state, updating: false };

    case FORM_TYPE.FILTER_APPLICATION_FORM_SUCCESS: {
      const applicationList = [...action.payload].map((form) => {
        var scoreA = 0;
        thanhTichList.forEach((thanhTich) => {
          form?.[thanhTich].forEach((infoObj) => scoreA += infoObj.score);
        });
        return ({ ...form, scoreA });
      });

      return {
        ...state,
        applicationList,
        loading: false
      };
    }

    case FORM_TYPE.FILTER_APPLICATION_FORM_SEARCH_SUCCESS:
      return { ...state, applicationSearchList: action.payload, loading: false };

    case FORM_TYPE.INSERT_APPLICATION_FORM_SUCCESS:
      return { ...state, application: action.payload, updating: false };

    case FORM_TYPE.UPDATE_APPLICATION_FORM_SUCCESS: {
      const applicationList = [...state.applicationList];
      const index = applicationList.findIndex((e) => e.id === action.payload.id);
      if (index >= 0) {
        applicationList[index] = { ...action.payload };
      }
      return {
        ...state,
        updating: false,
        application: action.payload,
        applicationList,
      };
    }

    case FORM_TYPE.GET_APPLICATION_BY_PROFILE_ID_SUCCESS:
      return { ...state, myApplicationFormList: action.payload, loading: false };

    case PROFILE_TYPE.GET_PROFILE_LIST_SUCCESS:
      return { ...state, applicationList: action.profile, loading: false};

    case FORM_TYPE.APPROVE_APPLICATION_FORM_SUCCESS:
      return { ...state, loading: false };
    
    case PROFILE_TYPE.GET_PROFILE_FILTER_FORM_SUCCESS:
      return { ...state, applicationFilter: action.profile, loading: false};

    case FORM_TYPE.GET_APPLICATION_FORM_V2_SUCCESS:
      return { ...state, applicationv2: action.payload, loading: false};

    case FORM_TYPE.SET_FOCUS_FORM: {
      let _focusForm = {
        ...action.payload,
      };

      let scoreA = 0;
      thanhTichList.forEach((thanhTich) => {
        _focusForm?.[thanhTich].forEach((infoObj) => scoreA += infoObj.score);
      });

      if (_focusForm.category !== CATEGORY.KVC) {
        _focusForm.scoreA = scoreA;
      } else {
        _focusForm.scoreA = Math.min(scoreA, 50);
      }
      return { ...state, focusForm: { ..._focusForm } }
    }

    case FORM_TYPE.UPDATE_FOCUS_FORM: {
      let _focusForm = {
        ...state.focusForm,
        [action.name]: [
          ...state.focusForm[action.name].filter((e) => action.payload.findIndex((elm) => e.id === elm.id) < 0),
          ...action.payload,
        ].sort((a, b) => a.id - b.id)
      }

      let scoreA = 0;
      thanhTichList.forEach((thanhTich) => {
        _focusForm?.[thanhTich].forEach((infoObj) => scoreA += infoObj.score);
      });

      if (_focusForm.category !== CATEGORY.KVC) {
        _focusForm.scoreA = scoreA;
      } else {
        _focusForm.scoreA = Math.min(scoreA, 50);
      }

      return { ...state, focusForm: { ..._focusForm } }
    }

    case 'UPDATE_FOCUS_FORM_BY_DATA': {
      // console.log(
      //   state.focusForm[action.name].find((e) => e[action.child].id === action.payload.id),
      //   [
      //     ...state.focusForm[action.name].filter((e) => e[action.child].id !== action.payload.id),
      //     {
      //       ...state.focusForm[action.name].find((e) => e[action.child].id === action.payload.id),
      //       [action.child]: action.payload,
      //     }
      //   ]
      // );
      // console.log(...state.focusForm[action.name])
      let _focusForm = state.focusForm ? {
        ...state.focusForm,
        [action.name]: [
          ...state.focusForm[action.name]?.filter((e) => e[action.child].id !== action.payload.id),
          {
            ...state.focusForm[action.name].find((e) => e[action.child].id === action.payload.id),
            [action.child]: action.payload,
          }
        ].sort((a, b) => a.id - b.id)
      } : null;

      let scoreA = 0;
      thanhTichList.forEach((thanhTich) => {
        _focusForm?.[thanhTich].forEach((infoObj) => scoreA += infoObj.score);
      });
      _focusForm.scoreA = Math.round(scoreA * 100) / 100;

      return { ...state, focusForm: { ..._focusForm } }
    }

    case FORM_TYPE.THONG_KE_TAI_KHOAN_LOADING:
      return { ...state, thongKeLoading: true }
    case FORM_TYPE.THONG_KE_TAI_KHOAN_FAILURE:
      return { ...state, thongKeLoading: false }
    case FORM_TYPE.THONG_KE_TAI_KHOAN_SUCCESS:
      return {
        ...state,
        thongKeLoading: false,
        thongKeInfo: action.payload,
      }
    default:
      return state;
  }
}
