import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { fileRender } from '../../../utils/photoRender';

function MultiPreviewModal(props) {
  const [id, setId] = useState(0);

  const handleClose = () => {
    setId(0);
    props.handleClose();
  }

  const handleChangeId = (num) => {
    const newId = id + num;
    if (newId < props.url?.length && newId >= 0) setId(newId);
  }

  return (
    <Dialog fullWidth maxWidth='md' open={props.open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>File minh chứng</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {props.url && (
          <iframe
            frameBorder={0}
            src={fileRender(props.url[id])}
            style={{ minHeight: '75vh', width: 'calc(100% - 4px)', overflow: 'auto' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={4}>
            <Typography style={{ lineHeight: '36px' }}>Minh chứng: {`${id + 1}/${props.url?.length}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display='flex' justifyContent='center'>
              <Button
                onClick={() => {
                  fetch(`https://khxhnv.tainangviet.vn${props.url?.[id]}`)
                  .then(response => response.blob())
                  .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `${props.url?.[id]}.jpg`; // Specify the desired file name
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                  })
                  .catch(error => {
                    console.error('Error downloading file:', error);
                  });
                }}
                variant='outlined'
              >
                Download
              </Button>
              <Button
                target='_blank'
                rel='noreferrer'
                href={props.url?.[id] ? `${process.env.REACT_APP_DOMAIN}${props.url?.[id]}` : null}
                variant='outlined'
                style={{ marginLeft: 8 }}
              >
                Mở bản gốc
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display='flex' justifyContent='flex-end'>
              <Button variant='outlined' onClick={() => handleChangeId(-1)} style={{ marginRight: 8 }}>Trước</Button>
              <Button color='secondary' variant='outlined' onClick={() => handleChangeId(1)}>Sau</Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default MultiPreviewModal;
